import axios from "axios";
const profiles = axios.create({
  baseURL: `https://api.airtable.com/v0/${process.env.REACT_APP_PROFILE_BASE_ID}/People`,
  timeout: 10000,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
  },
});

const ranks = axios.create({
  baseURL: `https://api.airtable.com/v0/${process.env.REACT_APP_PROFILE_RANKS_BASE_ID}/Ranks%20Issued`,
  timeout: 10000,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
  },
});

export { profiles, ranks };

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import "./styles/ProfileHeader.css";
import "./styles/RankCard.css";

import { profiles } from "../api/airtableConfig";
import ProfileHeader from "./ProfileHeader";
import Ranks from "./ranks/Ranks";
import Achievements from "./achievements/Achievements";
import ProfileFooter from "./ProfileFooter";

export default function ProfilePage() {
  const [profile, setProfile] = useState({});
  const [active, setActive] = useState("RANKS");
  const { username } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await profiles.get(
        `?filterByFormula=(%7BUsername%7D%3D%22${username}%22)`
      );

      setProfile({ ...response.data.records[0] });
    };
    fetchData();
  }, [username]);

  return (
    <div className="sairaFont flex-column">
      <div className="fullPageHeader header-background d-flex justify-content-center"  style={{ height: "288px" }}>
        <div className="maxWidth">
          <ProfileHeader
            {...profile.fields}
            active={active}
            setActive={setActive}
          />
          {/* <ProfileTabs active={active} setActive={setActive} /> */}
        </div>
      </div>
      <div className="fullPageHeader bodyBackground d-flex justify-content-center " style={{paddingBottom: "60px"}}>
        {active === "RANKS" ? (
          <Ranks rankID={profile.fields ? profile.fields.Email : ""} />
        ) : (
          <Achievements />
        )}
      </div>
      <div className="bodyBackground d-flex justify-content-center pt-3" style={{marginTop: "3px"}}>
        <ProfileFooter username={username} />
      </div>
    </div>
  );
}

import React from "react";
import Modal from "react-modal";

import "../styles/AchievementCard.css";
import defaultPlacement from "../../assets/3rd place@2x.png";

Modal.setAppElement("#root");

export default function AchievementsDetails(props) {
  return (
    <Modal
      className="achievementsModal"
      overlayClassName="achievementsModalOverlay"
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
    >
      <nav className="nav achievement-nav justify-content-end mt-4 me-4">
        <div role="button" onClick={props.closeModal} className="nav-item">
          <i
            className="far fa-times-circle mt-2"
            style={{ fontSize: "32px", color: "#9D9D9D" }}
          ></i>
        </div>
      </nav>
      <div className="d-flex justify-content-center align-items-center achievementsDetail">
        <div
          className="mb-5 mt-0 achievementsItem d-flex flex-column justify-content-center align-items-center"
          style={{ width: "360px" }}
        >
          <div className="placementText">
            <p>{props.placement}</p>
            {props.verified ? (
              <i
                style={{ color: "#00D169", fontSize: "35px" }}
                className="fas fa-check-circle"
              ></i>
            ) : (
              <i
                style={{ color: "yellow", fontSize: "35px" }}
                className={"fas fa-exclamation-triangle"}
              ></i>
            )}
          </div>
          <div className="reset-margin">
            <p className="tournamentName">{props.tournament}</p>
            <p className="tournamentName">{props.challenge}</p>
            <p className="locationDetail">{props.location}</p>
          </div>
          <div className="category">
            <img
              src={defaultPlacement}
              alt="placement"
              className="my-4"
              style={{ width: "200px  ", height: "200px" }}
            />
            <p className="event-name">{props.category.belt}</p>
            <p className="achievementInfo m-0">{props.category.age}</p>
            <p className="achievementInfo m-0">{props.category.weight}</p>
          </div>
        </div>{" "}
      </div>
    </Modal>
  );
}

import React from "react";
import { useAlert } from "react-alert";
import "./styles/ProfileFooter.css";

export default function ProfileFooter(props) {
  const alert = useAlert();
  return (
    <div className="footer">
      <div className="d-flex flex-column main-text">
        <p className="footer-text">SHARE YOUR</p>
        <p className="footer-text">DOJO+ PROFILE</p>
      </div>
      <div className="d-flex justify-content-center link-rectangle">
        <p className="p-2">{`profile.dojo.plus/${props.username}`}</p>
      </div>
      <div>
        <button
          onClick={() => {
            navigator.clipboard.writeText(
              `profile.dojo.plus/${props.username}`
            );
            alert.show(
              <div
                style={{
                  color: "white",
                  fontFamily: "Saira",
                }}
              >
                Profile link copied to clipboard!
              </div>
            );
          }}
          className="share btn"
        >
          COPY
        </button>
      </div>
    </div>
  );
}

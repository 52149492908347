function Redirect() {
  return (
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="refresh" content="0; url=http://bb.dojo.plus" />
        <title>DOJO+</title>
      </head>
      <body></body>
    </html>
  );
}

export default Redirect;

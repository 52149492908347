import React, { useState } from "react";

import AchievementsCard from "./AchievementsCard";
import AchievementsDetails from "./AchievementsDetails";

import "../styles/AchievementCard.css";

export default function Achievements() {
  const [modalIsOpen, setModalOpen] = useState(false);
  const [openedAchievement, setOpenedAchievement] = useState({
    placement: "",
    verified: false,
    tournament: "",
    challenge: "",
    location: "",
    category: {
      belt: "",
      age: "",
      weight: "",
    },
    placementImage: "",
  });

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = (clickedAchievement) => {
    setModalOpen(true);
    setOpenedAchievement(clickedAchievement);
  };

  const testArr = new Array(9).fill(0);
  return (
    <div className="achievementsContainer maxWidth">
      {testArr.map((item, i) => (
        <AchievementsCard key={i} onClick={openModal} />
      ))}
      <div className="d-flex justify-content-center align-items-center">
        <AchievementsDetails
          {...{ modalIsOpen, closeModal, ...openedAchievement }}
        />
      </div>
    </div>
  );
}

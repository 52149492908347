import React, { useState, useEffect } from "react";
import { profiles } from "../api/airtableConfig";
import ProfileCard from "./ProfileCard";

import "./styles/ProfileList.css";
import "./styles/ProfileHeader.css";

export default function ProfilesList() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const fetchUsers = async () => {
      const response = await profiles.get("/");
      const displayUsers = response.data.records.filter(
        (user) =>
          user.fields.Username &&
          user.fields["Display Name"]
            .toLowerCase()
            .includes(search.toLowerCase())
      );
      setUsers([...displayUsers]);
    };
    fetchUsers(search);
  }, [search]);
  return (
    <div className="fullPageHeader bodyBackground d-flex flex-column justify-content-center align-items-center ">
      <div className="maxWidth my-2">
        <div className="input-group searchBar">
          <span
            className="input-group-text"
            style={{ color: "grey", backgroundColor: "white" }}
          >
            <i className="fas fa-search"></i>
          </span>
          <input
            type="text"
            name="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className="form-control "
          />
        </div>
      </div>
      <div className="maxWidth">
        {users.map((user) => (
          <ProfileCard
            key={user.fields.Email ? user.fields.Email : user.fields.Username}
            user={user}
          />
        ))}
      </div>
    </div>
  );
}

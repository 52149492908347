import React from "react";

import "../styles/RankCard.css";
import "../styles/ProfileHeader.css";

import defaultRank from "../../assets/0degree@3x.png";
import profileFallBack from "../../assets/profileFallback.png";

export default function RankCard(props) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const graduated = new Date(props.Graduated);
  const verified = props.Verified ? true : false;
  const [rankName, rankDegree] = props.Rank ? props.Rank.split(" – ") : "";

  const martialArt = props["Martial Art (from Martial Arts Ranks)"];
  const rankPic = props["Rank Image w375 h24 (from Martial Arts Ranks)"]
    ? props["Rank Image w375 h24 (from Martial Arts Ranks)"][0].url
    : "";
  const schoolLogo = props["School Logo (from All Schools)"]
    ? props["School Logo (from All Schools)"][0].url
    : "";

  return (
    <div className="bodyBackground rank-card-padding">
      <div className=" border-box-card">
        <div className="px-3 pt-3 d-flex justify-content-between">
          <small style={{ color: "#808080" }}>
            {`${monthNames[graduated.getMonth()]} ${graduated.getFullYear()}`}
          </small>
          <div
            className="py-1 px-2"
            style={{
              borderRadius: "12px",
              backgroundColor: "#282828",
              color: "#999999",
              letterSpacing: 0,
              fontSize: "12px",
              textAlign: "center",
              verticalAlign: "middle",
              height: "24px",
            }}
          >
            {verified ? (
              <p className="centerText">
                <i
                  style={{ color: "#00D169" }}
                  className="fas fa-check-circle"
                ></i>{" "}
                VERIFIED
              </p>
            ) : (
              <p className="centerText">
                <i
                  style={{ color: "yellow" }}
                  className={"fas fa-exclamation-triangle"}
                ></i>{" "}
                NOT VERIFIED
              </p>
            )}
          </div>
        </div>
        <div className="px-3 mx-0 my-2">
          <div className="rank-stripe">
            <p className="martial-art">
              {martialArt ? martialArt.toUpperCase() : ""}
            </p>
            <p className="rank-level">{rankName}</p>{" "}
          </div>
          <small className="of-stripes">
            {rankDegree
              ? `${rankDegree.match(/[\d]/g)[0]} Stripe${
                  +rankDegree.match(/[\d]/g)[0] > 1 ? "s" : ""
                }`
              : ""}
          </small>
        </div>
        <img
          style={{
            margin: "0",
            width: "100%",
            height: "24px",
            padding: "0",
          }}
          src={rankPic ? rankPic : defaultRank}
          alt="rank"
        />
        <div className="group mt-3 px-3">
          <div className="d-flex ">
            <div>
              <img
                className="school-logo-size-48-x-48-mediu me-2"
                src={profileFallBack}
                alt="master"
              />
              <div
                style={{
                  width: "48px",
                  height: "7px",
                  position: "relative",
                  backgroundColor: "grey",
                  top: "-7px",
                  left: "0",
                }}
              ></div>
            </div>
            <div className="m-0">
              <p className="school-name m-0">Mario Sperry Miami</p>
              <p className="instructor-name m-0">Mario Sperry</p>
            </div>
          </div>
          <div>
            <img
              className="school-logo-size-48-x-48-mediu rounded-pic"
              src={schoolLogo}
              alt="school logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

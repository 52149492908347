import React, { useState, useEffect } from "react";
import RankCard from "./RankCard";
import { ranks } from "../../api/airtableConfig";

import "../styles/ProfileHeader.css";
import "../styles/RankCard.css";

export default function Ranks(props) {
  const [ranksArr, setRanksArr] = useState([]);
  const { rankID } = props;
  useEffect(() => {
    const fetchData = async () => {
      const filteredRanks = await ranks.get(
        `?filterByFormula=(%7BEmail%7D%3D%22${encodeURIComponent(rankID)}%22)`
      );
      setRanksArr([...filteredRanks.data.records]);
    };
    fetchData();
  }, [rankID]);
  return (
    <div className="maxWidth">
      {ranksArr.map((rank, i) => (
        <RankCard
          key={rank.fields ? rank.fields.ID : i}
          {...(rank.fields ? rank.fields : "")}
        />
      ))}
    </div>
  );
}

import React from "react";

import defaultPlacement from "../../assets/3rd place@2x.png";

import "../styles/AchievementCard.css";

export default function AchievementsCard(props) {
  const verified = false;
  return (
    <div
      role="button"
      onClick={() =>
        props.onClick({
          placement: "3rd",
          verified: false,
          tournament: "New Breed",
          challenge: "Ultimate Challenge",
          location: "Coral Springs 2019",
          category: {
            belt: "White Belt",
            age: "Adult",
            weight: "65kg",
          },
          placementImage: defaultPlacement,
        })
      }
      className="my-4 achievementsItem d-flex flex-column justify-content-center align-items-center"
    >
      <img src={defaultPlacement} alt="placement" />
      <div className="text-space">
        <p className="rank mt-2">
          3rd
          {verified ? (
            <i style={{ color: "#00D169" }} class="fas fa-check-circle"></i>
          ) : (
            <i
              style={{ color: "yellow" }}
              className={"fas fa-exclamation-triangle"}
            ></i>
          )}
        </p>
      </div>
      <div className="text-space">
        <p className="event-name">ATLANTA GRAPPLING</p>
        <p className="achievementInfo m-0">Atlanta</p>
        <p className="achievementInfo m-0">2019</p>
      </div>
    </div>
  );
}

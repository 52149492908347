import React, { useState, useEffect } from "react";
import dojoLogo from "../assets/DOJO+Logo@2x.png";
import profileFallback from "../assets/profileFallback.png";
import defaultRank from "../assets/0degree@3x.png";

import "./styles/ProfileHeader.css";

export default function ProfileHeader(props) {
  const displayName = props["Display Name"] ? props["Display Name"] : "";
  const firstName = props.Username
    ? props.Username
    : props["First Name"]
    ? "@" + props["First Name"].toLowerCase().split(" ").join("")
    : "";
  const photo = props.Photo ? props.Photo[0].url : profileFallback;
  const belt = props["Rank w375 h24"]
    ? props["Rank w375 h24"][0].url
    : defaultRank;
  const facebook = props.Facebook ? props.Facebook : "";
  const youtube = props.Youtube ? props.Youtube : "";
  const twitter = props.Twitter ? props.Twitter : "";
  const instagram = props.Instagram ? props.Instagram : "";

  const [small, setSmall] = useState(false);
  useEffect(() => { 
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 100)
      );
    }
  });

  return (
    <div
      className="sairaFont header-background d-flex flex-column justify-content-around"
      style={{ height: "100%" }}
    >
      <nav
        className={`${
          small ? "small" : ""
        } whiteFont header-background profileNav navbar navbar-dark d-flex justify-content-center`}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100vw",
          height: "80px",
          margin: "0",
          zIndex: 1
        }}
      >
        {/* <div role="button" onClick={() => history.push("/")}>
          <i
            style={{ marginLeft: "20px", fontSize: "24px" }}
            className="fas fa-chevron-left "
          ></i>
        </div> */}

        <h1 className = {small ? "animationname" : ""} style={{ fontWeight: "600" }}>
          {small ? (
            displayName 
          ) : (
            <img className = {small ? "animationlogo" : ""} style={{ width: "100px" }} src={dojoLogo} alt="dojo+" />
          )}
        </h1>
        {/* <div></div> */}
      </nav>

      {/* Profile picture with stripes background and bottom name*/}
      <div
        style={{ marginTop: "80px" }}
        className=" d-flex justify-content-center align-items-center flex-column "
      >
        <div
          style={{ width: "100%" }}
          className="avatar-container avatar-border d-flex justify-content-center align-items-center position-relative"
        >
          {/* Missing background stripe, missing profile picture*/}
          <img
            className="belt position-relative"
            style={{ width: "100%" }}
            src={belt}
            alt="belt"
          />
          <img
            alt="avatar"
            className="avatar whiteFont position-absolute"
            src={photo}
          ></img>
        </div>
        <div className="profileAt whiteFont">
          <p>{firstName}</p>
        </div>
      </div>
      {/* Social Media icons  */}
      <div className="social-icons whiteFont  d-flex justify-content-center align-items-center ">
        <a href={instagram} className="link whiteFont">
          <i className="fab fa-instagram"></i>
        </a>
        <a href={twitter} className="link whiteFont">
          <i className="fab fa-twitter"></i>
        </a>
        <a href={youtube} className="link whiteFont">
          <i className="fab fa-youtube"></i>
        </a>
        <a href={facebook} className="link whiteFont">
          <i className="fab fa-facebook-square"></i>
        </a>
      </div>
      <div className="bottomTabs">
        <div
          className="sairaFont margin-bottom d-flex justify-content-around header-background"
          style={{ height: "25px", bottom: "0" }}
        >
          <div
            className={`profileTabs ${
              props.active === "RANKS" ? "whiteFont" : "greyFont"
            }`}
            role="button"
            onClick={() => props.setActive("RANKS")}
          >
            RANKS
            {props.active === "RANKS" ? (
              <div className="selectedBorder"></div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`profileTabs ${
              props.active === "ACHIEVEMENTS" ? "whiteFont" : "greyFont"
            }`}
            role="button"
            onClick={() => props.setActive("ACHIEVEMENTS")}
          >
            ACHIEVEMENTS
            {props.active === "ACHIEVEMENTS" ? (
              <div className="selectedBorder"></div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ProfilePage from "./Profile/ProfilePage";
import ProfilesList from "./Profile/ProfilesList";
import Redirect from "./Profile/Redirect";
import "./styles/App.css";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Redirect} />
        <Route exact path="/profiles" component={ProfilesList} />
        <Route path="/:username" component={ProfilePage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

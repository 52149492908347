import React from "react";
import { Link } from "react-router-dom";

import profileFallback from "../assets/profileFallback.png";

import "./styles/ProfileList.css";
import "./styles/ProfileHeader.css";

export default function ProfileCard(props) {
  const belt = props.user.fields["Rank w375 h24"]
    ? props.user.fields["Rank w375 h24"][0].url
    : false;
  return (
    <Link
      to={`/${props.user.fields.Username}`}
      style={{ textDecoration: "inherit" }}
    >
      <div className="d-flex flex-column profile">
        <div
          className="d-flex"
          style={{
            backgroundColor: "white",
            maxHeight: "288px",
            border: "1px solid white",
          }}
        >
          <div className="mx-2">
            <img
              src={
                props.user.fields.Photo
                  ? props.user.fields.Photo[0].url
                  : profileFallback
              }
              className="profileImg"
              alt="userFallback"
            />
            {belt ? (
              <div>
                <img
                  alt="rank"
                  src={belt}
                  style={{
                    width: "96px",
                    height: "15px",
                    position: "relative",
                    backgroundColor: "grey",
                    top: "-30px",
                    left: "15px",
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "96px",
                  height: "15px",
                  position: "relative",
                  backgroundColor: "grey",
                  top: "-30px",
                  left: "15px",
                }}
              ></div>
            )}
          </div>
          <div className="d-flex flex-column justify-content-center me-3">
            <div>
              <h5 style={{ color: "black" }}>
                {props.user.fields["Display Name"]
                  ? props.user.fields["Display Name"]
                  : props.user.fields["First Name"]}
              </h5>
              <p>
                <small className="text-muted">
                  {props.user.fields.Username}
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "1px", backgroundColor: "white", border: "none" }}
      >
        <hr
          className="m-0 p-0"
          style={{ width: "90% ", height: "1px", color: "#282828" }}
        ></hr>
      </div>
    </Link>
  );
}
